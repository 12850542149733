import platformEnv from'@onekeyhq/shared/src/platformEnv';export var browserTypeHandler=function(){if(false||false){return'MultiTabBrowser';}return'StandardBrowser';}();export var webviewRefs={};export var captureViewRefs={};if("production"!=='production'){globalThis.$$webviewRefs=webviewRefs;}export function getWebviewWrapperRef(id){var ref=id?webviewRefs[id]:null;return ref!=null?ref:null;}export function formatHiddenHttpsUrl(url){return{isHttpsUrl:url&&/^https/i.test(url),hiddenHttpsUrl:url?.replace?.(/^https:\/\//i,'')};}export function crossWebviewLoadUrl({url,tabId}){var wrapperRef=getWebviewWrapperRef(tabId);if(false){setTimeout(function(){wrapperRef?.innerRef?.loadURL(url).catch();});}else if(platformEnv.isRuntimeBrowser){wrapperRef?.innerRef?.loadURL(url);}else{wrapperRef?.innerRef?.loadUrl(url);}}var injectToDismissWebviewKeyboard=`
(function(){
  document.activeElement && document.activeElement.blur()
})()
`;export function dismissWebviewKeyboard(id){var ref=getWebviewWrapperRef(id);if(ref){if(false){try{ref.innerRef?.injectJavaScript(injectToDismissWebviewKeyboard);}catch(error){}}if(false){var deskTopRef=ref.innerRef;if(deskTopRef){try{deskTopRef.executeJavaScript(injectToDismissWebviewKeyboard);}catch(e){}}}}}export var injectToPauseWebsocket=`
(function(){
  if (window.WebSocket) {
    if (!window.$$onekeyWebSocketSend) {
      window.$$onekeyWebSocketSend = window.WebSocket.prototype.send;
    }
    window.WebSocket.prototype.send = () => {};
  }
})()
`;export var injectToResumeWebsocket=`
(function(){
  if (
    window.WebSocket &&
    window.$$onekeyWebSocketSend
  ) {
    window.WebSocket.prototype.send = window.$$onekeyWebSocketSend;
  }
})()
`;